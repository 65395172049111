.pony-container {
    display: grid;
    overflow-y: scroll;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1rem));
    width: 90vw;
    grid-gap: 1rem;
    align-items: stretch;
    height: 100vh;
    justify-content: center;
    background-color: rgb(50, 50, 50);
    text-align: center;
}

.pony-list-img {
    margin-left: auto;
    margin-right: auto;
    max-width: 256px;
    max-height: 256px;
    width: auto;
    height: auto;
    object-fit: contain;
}