.game-img {
    flex: 1;
    object-fit: contain;
    max-width: 80vw;
    width: auto;
    margin: 30px;
    max-height: 65vh;
}

.pony-name {
    font-size: 5vh;
    margin-top: 2vh;
    text-align: center;
}

.progress-display {
    font-size: 2vh;
}

.cached-img {
    visibility: hidden;
    display: flex;
    width: 1px;
    height: 1px;
}
