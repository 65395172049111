@import url('https://fonts.googleapis.com/css2?family=Flavors&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Creepster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.eventsDiv {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.event {
    position: relative;
    z-index: -999;
    pointer-events: none;
}


.starWars {
    height: auto;
    max-height: 50%;
    width: auto;
    max-width: 100vw;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: -999;
    pointer-events: painted;
}

.valentines {
    height: auto;
    max-height: 50%;
    width: auto;
    max-width: 100vw;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: -999;
    pointer-events: painted;
}

.easter-pony {
    height: auto;
    max-height: 45%;
    width: auto;
    max-width: 100vw;
    transform: scaleX(-1);
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: -999;
    pointer-events: painted;
}

.xmas-tree {
    height: auto;
    max-height: 45%;
    width: auto;
    max-width: 100vw;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: -999;
    pointer-events: painted;
}

.new-year {
    height: 50%;
    width: max-content;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: -999;
    pointer-events: painted;
}

.bday {
    height: 50%;
    width: max-content;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: -999;
    pointer-events: painted;
}

.cobweb {
    height: 30%;
    width: fit-content;
    margin-left: 0;
    margin-right: auto;
    z-index: -999;
    transform: scaleX(-1);
    pointer-events: none;
}

div.vector {
    margin: 10px auto;
    height: 250px;
    width: 600px;
    overflow: hidden;
    position: relative;
}

svg {
    height: 100%;
    width: 100%;
}

line,
path {
    stroke-width: 2px;
    fill: lightgray;

}

#top {
    stroke-width: 2px;
}

/* Snowflakes */

.snowflake {
    color: #fff;
    font-size: 1em;
    font-family: Arial, sans-serif;
    text-shadow: 0 0 5px #000;
}

.snowflake,
.snowflake .inner {
    animation-iteration-count: infinite;
    animation-play-state: running
}

@keyframes snowflakes-fall {
    0% {
        transform: translateY(0)
    }

    100% {
        transform: translateY(110vh)
    }
}

@keyframes snowflakes-shake {

    0%,
    100% {
        transform: translateX(0)
    }

    50% {
        transform: translateX(80px)
    }
}

.snowflake {
    position: fixed;
    top: -10%;
    z-index: 9999;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    animation-name: snowflakes-shake;
    animation-duration: 3s;
    animation-timing-function: ease-in-out
}

.snowflake .inner {
    animation-duration: 10s;
    animation-name: snowflakes-fall;
    animation-timing-function: linear
}

.snowflake:nth-of-type(0) {
    left: 1%;
    animation-delay: 0s
}

.snowflake:nth-of-type(0) .inner {
    animation-delay: 0s
}

.snowflake:first-of-type {
    left: 10%;
    animation-delay: 1s
}

.snowflake:first-of-type .inner,
.snowflake:nth-of-type(8) .inner {
    animation-delay: 1s
}

.snowflake:nth-of-type(2) {
    left: 20%;
    animation-delay: .5s
}

.snowflake:nth-of-type(2) .inner,
.snowflake:nth-of-type(6) .inner {
    animation-delay: 6s
}

.snowflake:nth-of-type(3) {
    left: 30%;
    animation-delay: 2s
}

.snowflake:nth-of-type(11) .inner,
.snowflake:nth-of-type(3) .inner {
    animation-delay: 4s
}

.snowflake:nth-of-type(4) {
    left: 40%;
    animation-delay: 2s
}

.snowflake:nth-of-type(10) .inner,
.snowflake:nth-of-type(4) .inner {
    animation-delay: 2s
}

.snowflake:nth-of-type(5) {
    left: 50%;
    animation-delay: 3s
}

.snowflake:nth-of-type(5) .inner {
    animation-delay: 8s
}

.snowflake:nth-of-type(6) {
    left: 60%;
    animation-delay: 2s
}

.snowflake:nth-of-type(7) {
    left: 70%;
    animation-delay: 1s
}

.snowflake:nth-of-type(7) .inner {
    animation-delay: 2.5s
}

.snowflake:nth-of-type(8) {
    left: 80%;
    animation-delay: 0s
}

.snowflake:nth-of-type(9) {
    left: 90%;
    animation-delay: 1.5s
}

.snowflake:nth-of-type(9) .inner {
    animation-delay: 3s
}

.snowflake:nth-of-type(10) {
    left: 25%;
    animation-delay: 0s
}

.snowflake:nth-of-type(11) {
    left: 65%;
    animation-delay: 2.5s
}

/* Ballons */

@keyframes balloons-fall {
    0% {
        transform: translateY(110vh)
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes balloons-shake {

    0%,
    100% {
        transform: translateX(0)
    }

    50% {
        transform: translateX(30px)
    }
}

.balloon {
    color: #fff;
    font-size: 1em;
    font-family: Arial, sans-serif;
    text-shadow: 0 0 5px #000;
}

.balloon,
.balloon .inner {
    animation-iteration-count: infinite;
    animation-play-state: running
}


.balloon {
    position: fixed;
    top: -10%;
    z-index: 9999;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    animation-name: balloons-shake;
    animation-duration: 3s;
    animation-timing-function: ease-in-out
}

.balloon .inner {
    animation-duration: 10s;
    animation-name: balloons-fall;
    animation-timing-function: linear
}

.balloon:nth-of-type(0) {
    left: 1%;
    animation-delay: 0s
}

.balloon:nth-of-type(0) .inner {
    animation-delay: 0s
}

.balloon:first-of-type {
    left: 10%;
    animation-delay: 1s
}

.balloon:first-of-type .inner,
.balloon:nth-of-type(8) .inner {
    animation-delay: 1s
}

.balloon:nth-of-type(2) {
    left: 20%;
    animation-delay: .5s
}

.balloon:nth-of-type(2) .inner,
.balloon:nth-of-type(6) .inner {
    animation-delay: 6s
}

.balloon:nth-of-type(3) {
    left: 30%;
    animation-delay: 2s
}

.balloon:nth-of-type(11) .inner,
.balloon:nth-of-type(3) .inner {
    animation-delay: 4s
}

.balloon:nth-of-type(4) {
    left: 40%;
    animation-delay: 2s
}

.balloon:nth-of-type(10) .inner,
.balloon:nth-of-type(4) .inner {
    animation-delay: 2s
}

.balloon:nth-of-type(5) {
    left: 50%;
    animation-delay: 3s
}

.balloon:nth-of-type(5) .inner {
    animation-delay: 8s
}

.balloon:nth-of-type(6) {
    left: 60%;
    animation-delay: 2s
}

.balloon:nth-of-type(7) {
    left: 70%;
    animation-delay: 1s
}

.balloon:nth-of-type(7) .inner {
    animation-delay: 2.5s
}

.balloon:nth-of-type(8) {
    left: 80%;
    animation-delay: 0s
}

.balloon:nth-of-type(9) {
    left: 90%;
    animation-delay: 1.5s
}

.balloon:nth-of-type(9) .inner {
    animation-delay: 3s
}

.balloon:nth-of-type(10) {
    left: 25%;
    animation-delay: 0s
}

.balloon:nth-of-type(11) {
    left: 65%;
    animation-delay: 2.5s
}