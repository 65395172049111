#buttons-holder {
    display: flex;
    gap: 10px;
    width: 100vw;
}

.button {
    background-color: rgb(70, 70, 70);
    font-size: 2.94vh;
    padding: 5px;
    font-weight: 700;
    text-align: center;
    user-select: none;
    cursor: pointer;
    flex: 1;
    border-bottom: 10px solid white;
    height: 10.5vh;
    padding: 1.5vh;
    touch-action: manipulation;
}

.button-combo {
    color: white;
    font-weight: normal;
}


@keyframes smash-button-frames {
    0% {
        background-color: rgb(0, 255, 0);
    }

    100% {
        background: rgb(70, 70, 70);
    }
}


@keyframes pass-button-frames {
    0% {
        background-color: rgb(255, 0, 0);
    }

    100% {
        background: rgb(70, 70, 70);
    }
}

.smash-button {
    color: rgb(0, 255, 0);
    border-color: rgb(0, 255, 0);
}

.smash-button-anim {
    animation: smash-button-frames 250ms;
}

.pass-button {
    color: rgb(255, 0, 0);
    border-color: rgb(255, 0, 0);
}


.pass-button-anim {
    animation: pass-button-frames 250ms;
}